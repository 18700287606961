<template>
    <svg class="logotype" viewBox="0 0 822 306" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <path
            d="M318.6,294.36C313.31,301.44 304.29,305.48 290.83,305.48C284.06,305.48 278.31,304.47 273.41,302.37C263.61,298.33 258.17,289.93 258.17,277.02L258.17,276.79C258.17,270.65 259.57,265.44 262.53,261.08C268.29,252.29 278.71,247.47 291.85,247.47C297.37,247.47 302.12,248.33 306.63,249.88C315.5,253.15 321.56,259.52 323.51,269.4L313.24,269.4C311.3,260.92 304.22,256.18 291.93,256.18C277.23,256.18 268.13,262.87 268.13,276.87L268.13,276.95C268.13,290.17 276.37,296.78 291.38,296.78C305.38,296.78 311.83,290.87 313.47,282.08L323.81,282.08C322.95,286.75 321.24,290.79 318.6,294.37L318.6,294.36Z"
            style="fill-rule:nonzero;" />
        <path
            d="M359.44,290.16L352.52,304L342.1,304L370.8,248.7L380.83,248.7L410,304L399.73,304L392.57,290.16L359.44,290.16ZM363.41,282.23L388.53,282.23L375.78,257.5L363.41,282.23Z"
            style="fill-rule:nonzero;" />
        <path
            d="M443.35,304.01L433.86,304.01L433.86,248.71L484.57,248.71L484.57,257.27L443.35,257.27L443.35,274.3L482.78,274.3L482.78,282.86L443.35,282.86L443.35,304.01Z"
            style="fill-rule:nonzero;" />
        <path
            d="M510.86,304.01L510.86,248.71L562.97,248.71L562.97,256.88L520.12,256.88L520.12,272.59L556.36,272.59L556.36,280.68L520.12,280.68L520.12,295.53L564.53,295.53L564.53,304.01L510.86,304.01ZM558.46,224.06L541.51,241.4L533.5,241.4L547.73,224.06L558.46,224.06Z"
            style="fill-rule:nonzero;" />
        <path
            d="M105.4,151.33L89.87,133.18C86.05,128.72 80.63,124.18 73.63,119.56C66.62,114.94 60.97,112.63 56.67,112.63L56.67,110.24L71,88.74C73.87,85.08 76.98,83.24 80.32,83.24C82.54,83.24 86.05,84.28 90.83,86.35C91.31,86.51 93.01,87.19 95.96,88.38C98.91,89.57 102.05,90.17 105.39,90.17C110.8,90.17 115.26,88.46 118.77,85.03C122.27,81.61 124.03,77.19 124.03,71.78C124.03,66.37 122.27,61.95 118.77,58.52C115.27,55.1 110.81,53.38 105.39,53.38C97.75,53.38 90.7,55.62 84.25,60.07C77.8,64.53 71.55,71.3 65.5,80.38C60.88,87.07 57.54,92.17 55.46,95.67C52.43,100.45 50.09,103.99 48.42,106.3C46.75,108.61 44.95,110.48 43.04,111.92L43.04,9.44L35.63,9.44C28.14,12.63 20.42,14.22 12.46,14.22L0.04,14.22L0.04,22.34C3.86,22.34 6.77,23.77 8.76,26.64C10.75,29.5 11.98,33.81 12.47,39.54L12.47,151.34C11.99,157.07 10.76,161.38 8.76,164.24C6.77,167.1 3.87,168.54 0.04,168.54L0.04,176.66L55.7,176.66L55.7,168.54C51.4,168.54 48.21,166.59 46.15,162.69C44.08,158.79 43.04,152.94 43.04,145.13L43.04,124.11C43.99,123.79 44.79,123.63 45.43,123.63C50.05,123.63 54.51,126.34 58.81,131.75C63.11,137.16 68.04,144.88 73.62,154.92C75.21,158.9 76.01,161.61 76.01,163.04C76.01,164.47 75.45,165.51 74.33,166.14C73.22,166.78 71.38,167.09 68.83,167.09L68.83,176.65L130.46,176.65L130.46,167.09C126.64,167.09 122.82,165.9 119,163.51C115.18,161.12 110.64,157.06 105.38,151.32L105.4,151.33Z"
            style="fill-rule:nonzero;" />
        <path
            d="M262.11,162.68C260.04,158.78 259.01,152.93 259.01,145.12L259.01,100.45C259.01,83.25 253.71,71.1 243.12,64.02C232.52,56.93 218.71,53.39 201.67,53.39C183.04,53.39 168.98,56.42 159.51,62.47C150.04,68.52 145.3,76.49 145.3,86.36C145.3,91.3 146.9,95.47 150.08,98.9C153.26,102.33 157.32,104.04 162.26,104.04C167.2,104.04 171.61,102.05 175.04,98.07C178.46,94.09 180.18,89.16 180.18,83.26C180.18,77.36 182.29,72.31 186.51,68.57C190.73,64.83 196.03,62.96 202.4,62.96C208.77,62.96 214.23,64.51 219.24,67.61C224.26,70.72 227.24,76.49 228.2,84.93C228.04,91.62 226.05,96.88 222.22,100.7C218.4,104.52 213.7,107.35 208.13,109.18C202.56,111.01 195.23,112.8 186.16,114.55C176.44,116.47 168.68,118.37 162.86,120.28C157.04,122.19 152.15,125.3 148.17,129.6C144.19,133.9 142.2,139.88 142.2,147.52C142.2,157.87 146.14,165.95 154.03,171.77C161.91,177.58 171.5,180.49 182.82,180.49C194.14,180.49 203.99,177.82 211.48,172.49C218.96,167.16 224.62,160.27 228.44,151.83L228.44,153.03C228.44,158.28 228.75,162.55 229.39,165.81C230.02,169.08 231.57,171.71 234.04,173.69C236.51,175.69 240.21,176.68 245.15,176.68L271.66,176.68L271.66,168.56C267.36,168.56 264.17,166.61 262.11,162.71L262.11,162.68ZM228.43,128.64C228.43,133.58 227.39,138.71 225.32,144.05C223.25,149.39 219.7,154.01 214.69,157.91C209.67,161.82 203.02,163.76 194.74,163.76C188.21,163.76 182.92,161.93 178.85,158.26C174.79,154.6 172.76,149.74 172.76,143.69C172.76,139.39 173.99,135.89 176.46,133.18C178.93,130.47 181.99,128.28 185.66,126.61C189.32,124.94 194.42,123.07 200.95,120.99C207.8,118.6 213.29,116.54 217.43,114.78C221.57,113.03 225.23,110.8 228.42,108.1L228.42,128.64L228.43,128.64Z"
            style="fill-rule:nonzero;" />
        <path
            d="M376.06,121.47C370.48,118.45 362.83,114.78 353.12,110.48C344.2,106.66 337.23,103.39 332.22,100.69C327.2,97.98 322.94,94.75 319.44,91.01C315.94,87.27 314.18,82.93 314.18,77.99C314.18,73.05 316.21,69.19 320.27,66.4C324.33,63.62 329.7,62.22 336.39,62.22C343.08,62.22 348.89,64.02 352.87,67.6C356.85,71.19 358.84,76.08 358.84,82.29C358.84,87.55 360.63,91.93 364.22,95.43C367.8,98.93 372.46,100.69 378.2,100.69C382.97,100.69 387.08,99.18 390.5,96.15C393.92,93.13 395.64,89.3 395.64,84.68C395.64,74.33 390.3,66.53 379.64,61.27C368.97,56.02 354.79,53.39 337.12,53.39C321.19,53.39 308.81,56.3 299.97,62.11C291.13,67.93 286.72,76.25 286.72,87.08C286.72,93.93 288.87,99.86 293.17,104.87C297.47,109.89 302.61,114.03 308.58,117.29C314.55,120.56 322.56,124.34 332.59,128.63C341.03,132.3 347.48,135.24 351.94,137.47C356.4,139.7 360.18,142.41 363.29,145.59C366.39,148.77 367.94,152.44 367.94,156.58C367.94,161.04 365.63,164.66 361.02,167.45C356.4,170.23 350.74,171.63 344.06,171.63C337.38,171.63 331.76,169.88 327.7,166.37C323.64,162.87 321.61,158.09 321.61,152.04C321.61,146.63 319.73,142.21 315.99,138.79C312.25,135.37 307.59,133.65 302.02,133.65C296.76,133.65 292.54,135.04 289.36,137.83C286.17,140.61 284.58,144.32 284.58,148.94C284.58,159.3 289.67,167.14 299.87,172.47C310.06,177.8 324.71,180.47 343.83,180.47C359.6,180.47 372.26,177.56 381.81,171.75C391.37,165.94 396.14,158.18 396.14,148.46C396.14,142.41 394.19,137.15 390.29,132.69C386.39,128.23 381.65,124.49 376.08,121.46L376.06,121.47Z"
            style="fill-rule:nonzero;" />
        <path
            d="M477.45,163.52C474.98,166.71 471.84,168.3 468.02,168.3C457.99,168.3 452.97,159.62 452.97,142.26L452.97,70.12L476.38,70.12L476.38,62L452.97,62L452.97,19.48L447.95,19.48C447.16,22.66 444.8,27.76 440.91,34.77C437,41.78 432.27,48.07 426.7,53.64C421.12,59.22 415.47,62 409.74,62L409.74,70.12L422.4,70.12L422.4,144.17C422.72,155.32 425.99,164.12 432.19,170.57C438.41,177.02 446.69,180.24 457.04,180.24C466.27,180.24 473.72,177.57 479.38,172.24C485.03,166.91 487.86,159.94 487.86,151.34L481.17,151.34C481.17,156.28 479.94,160.34 477.46,163.53L477.45,163.52Z"
            style="fill-rule:nonzero;" />
        <path
            d="M594.03,151.93C585.03,156.16 576.39,158.26 568.11,158.26C563.01,158.26 558.15,157.27 553.54,155.28C548.92,153.29 545.25,150.82 542.55,147.87C539.84,144.92 538.49,142.1 538.49,139.39C538.49,135.25 540.91,132.34 545.77,130.67C550.63,128.99 558.23,127.44 568.59,126.01C578.94,124.74 587.46,123.23 594.15,121.47C600.84,119.72 606.65,116.61 611.59,112.15C616.52,107.7 619,101.4 619,93.28C619.16,85.64 616.85,78.79 612.07,72.73C607.29,66.68 600.44,61.94 591.52,58.52C582.6,55.1 572.09,53.38 559.99,53.38C540.72,53.38 526.03,58.76 515.92,69.5C505.81,80.25 500.75,95.98 500.75,116.68C500.75,137.38 506.05,153.55 516.64,164.22C527.23,174.89 541.21,180.22 558.57,180.22C574.34,180.22 587.43,176 597.87,167.56C608.3,159.12 615.43,148.92 619.25,136.98L615.43,134.35C610.17,141.84 603.04,147.69 594.05,151.91L594.03,151.93ZM531.8,109.05C531.8,96.47 533.99,85.68 538.38,76.68C542.75,67.68 549.96,63.19 559.99,63.19C567.79,63.19 574.64,65.58 580.54,70.36C586.43,75.13 589.38,81.75 589.38,90.18C589.38,95.91 587.9,100.57 584.96,104.16C582.01,107.75 578.43,110.45 574.21,112.28C569.99,114.11 564.38,116.07 557.37,118.13C551.32,119.88 546.46,121.52 542.8,123.03C539.13,124.54 536.11,126.49 533.72,128.88C532.45,123.31 531.81,116.7 531.81,109.06L531.8,109.05Z"
            style="fill-rule:nonzero;" />
        <path
            d="M726.6,151.93C717.6,156.16 708.96,158.26 700.68,158.26C695.58,158.26 690.72,157.27 686.11,155.28C681.49,153.29 677.83,150.82 675.12,147.87C672.41,144.92 671.06,142.1 671.06,139.39C671.06,135.25 673.49,132.34 678.35,130.67C683.21,128.99 690.81,127.44 701.16,126.01C711.51,124.74 720.03,123.23 726.72,121.47C733.41,119.72 739.22,116.61 744.16,112.15C749.1,107.7 751.57,101.4 751.57,93.28C751.72,85.64 749.42,78.79 744.64,72.73C739.87,66.68 733.02,61.94 724.1,58.52C715.18,55.1 704.67,53.38 692.57,53.38C673.3,53.38 658.61,58.76 648.5,69.5C638.39,80.25 633.33,95.98 633.33,116.68C633.33,137.38 638.63,153.55 649.22,164.22C659.81,174.89 673.79,180.22 691.15,180.22C706.92,180.22 720.01,176 730.44,167.56C740.87,159.12 748,148.92 751.82,136.98L748,134.35C742.74,141.84 735.61,147.69 726.62,151.91L726.6,151.93ZM664.37,109.05C664.37,96.47 666.56,85.68 670.94,76.68C675.32,67.68 682.52,63.19 692.56,63.19C700.36,63.19 707.21,65.58 713.1,70.36C718.99,75.13 721.94,81.75 721.94,90.18C721.94,95.91 720.46,100.57 717.52,104.16C714.57,107.75 710.99,110.45 706.77,112.28C702.55,114.11 696.93,116.07 689.93,118.13C683.88,119.88 679.01,121.52 675.36,123.03C671.69,124.54 668.67,126.49 666.28,128.88C665.01,123.31 664.37,116.7 664.37,109.06L664.37,109.05Z"
            style="fill-rule:nonzero;" />
        <path
            d="M811.77,162.68C810.65,160.56 809.86,157.82 809.34,154.56C811.79,153.37 813.3,151.92 813.3,150.33C813.3,148.6 811.53,147.04 808.69,145.81C808.69,145.57 808.67,145.37 808.67,145.13L808.67,9.44L808.64,9.44C808.9,3.85 808.61,0.24 807.49,0.09C806.11,-0.09 800.23,0.33 800.03,0.73C799.49,1.79 799.75,7.02 798.71,10.39C796.93,11.06 795.15,11.64 793.35,12.13C792.29,9.3 792.43,0.96 790.47,0.55C789.13,0.27 783.76,0.73 783.76,1.15C783.76,1.57 783.81,11.8 782.35,14.01C780.94,14.11 779.53,14.22 778.1,14.22L775.92,14.22C774.97,11.28 774.95,3.32 774.41,2.51C773.76,1.55 765.75,2.51 765.67,2.81C765.59,3.11 764.93,17.75 764.93,17.75L764.94,17.75C764.7,19.56 764.71,21.56 765.68,22.34C767.63,23.92 772.41,23.77 774.4,26.64C776.39,29.5 777.62,33.81 778.1,39.54L778.1,145.92C775.41,147.14 773.73,148.66 773.73,150.34C773.73,152.02 775.24,153.39 777.7,154.58C777.08,158.71 775.99,161.96 774.4,164.25C772.4,167.11 769.5,168.55 765.68,168.55L765.68,176.67L821.34,176.67L821.34,168.55C817.04,168.55 813.85,166.6 811.78,162.7L811.77,162.68Z"
            style="fill-rule:nonzero;" />
    </svg>
</template>