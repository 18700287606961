<template>
    <div class="layout__container" :class="classes">
        <PrimaryNav />
        <main>
            <div class="page" :class="`--${pageName} --page-transition-${pageTransition.direction}`">
                <slot />
            </div>
        </main>
        <footer>
            <div class="legal-notices">
                <div>&copy; 2023 Copyright Kasteel Café</div>
            </div>
        </footer>
    </div>
    <div class="layout__logo" :class="classes">
        <Logotype />
    </div>
    <div class="layout__background" :class="classes">
        <VideoBackground />
    </div>
    <GridOverlay />
</template>

<script>
export default {
    setup() {
        const route = useRoute()
        const pageTransition = reactive({
            direction: route.path === '/' ? 'out' : 'in',
            from: route.path
        })

        watch(route, route => {
            pageTransition.direction = 'between'

            if (pageTransition.from === route.path) {
                return
            }

            if (pageTransition.from === '/') {
                pageTransition.direction = 'in'
            } else if (route.path === '/') {
                pageTransition.direction = 'out'
            }

            pageTransition.from = route.path

            if (route.path === '/' && process.client) {
                window.scrollTo(0, 1)
            }
        })

        const error = useError()

        return {
            route,
            pageTransition,
            error
        }
    },
    computed: {
        pageName() {
            return this.route.name || this.error && 'error' || ''
        },
        classes() {
            return {
                '--has-page': this.route.path !== '/'
            }
        }
    }
}
</script>