<template>
    <div class="video-background">
        <video autoplay muted loop :poster="`/images/${videoPoster}`">
            <source :src="`/video/${videoFilename}`" type="video/mp4" />
        </video>
    </div>
</template>

<script setup>
// const videoFilename = 'pexels-kelly-2909914-640x338-24fps.mp4'
const videoPoster = 'Laptops-Shopfront-Background-Coloured-1080P-small.jpg'
const videoFilename = 'Laptops-Shopfront-Background-Coloured-1080P-small.mp4'
</script>