<template>
    <div class="grid-overlay" v-if="show">
        <div class="grid-overlay__column" v-for="n in columns" />
        <div class="active-breakpoint" />
    </div>
</template>

<script>
export default {
    data: () => ({
        show: false,
        columns: 6
    }),
    mounted() {
        document.body.addEventListener('keydown', this.handleKeydown);
        document.body.addEventListener('keyup', this.handleKeyup);
        this.updateColumns()
        if (process.client) {
            window.onresize = () => this.updateColumns()
        }

    },
    methods: {
        updateColumns() {
            this.columns = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--columns'))
        },

        handleKeydown(event) {
            if (event.code === 'Backquote') {
                this.updateColumns()
                this.show = !this.show
                console.log('columns:', this.columns);
            }
        },

        handleKeyup(event) {
            if (event.code === 'Backquote') {
                // this.show = false
            }
        }
    }
}
</script>